import * as React from "react";

import { Helmet } from "react-helmet";

import "@/pages/common.scss";
import PromoBlock from "@/components/common/promoBlock";
import SocialNetworkBlock from "@/components/main/socialNetworkBlock";
import ExpertSelection from "@components/main/ExpertsSelection";
import FAQ from "@components/main/FAQ";
import HowIsTaro from "@components/main/HowIsTaro";
import Questions from "@components/main/Questions";

import Chip from "./Chip";
import ConsultationInstruction from "./ConsultationInstruction";
import ExpertList from "./ExpertsList";
import HowIsConsultation from "./HowIsConsultation";
import Showcase from "./Showcase";
import Stories from "./Stories";
import VideoCarousel from "./VideoCarousel";
import VideoStorys from "./VideoStorys";
import "./styles.scss";

const Main = () =>
(
  <>
    <Helmet>
      <html lang="ru" />
      <body className="main-page--body" />
    </Helmet>
    <PromoBlock />
    <div className="main-page">
      <Showcase />
      <VideoStorys />
      <ExpertList />
      <HowIsConsultation />
      <Questions />
      <ConsultationInstruction />
      <VideoCarousel />
      <HowIsTaro />
      <ExpertSelection />
      <Stories />
      <FAQ />
      <Chip />
      <SocialNetworkBlock />
    </div>
  </>
);

export default Main;
