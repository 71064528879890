import React, { useState } from "react";

import { Link } from "gatsby";

import { marketingMainPage6, marketingMainPageReadMoreClickedHandler } from "@/marketing/utils";
import {
  Button, ButtonColorEnum, ButtonIconPositionEnum, ButtonSizeEnum,
} from "@components/common/button";
import { CATALOG_PAGE_NAME } from "@components/constants";

import { MAIN_PAGE_STORY_ITEMS } from "./constants";
import Item from "./Item";

import "./styles.scss";

const Stories = () => {
  const [storiesList, setStoriesList] = useState([MAIN_PAGE_STORY_ITEMS[0]]);

  function showMore() {
    marketingMainPageReadMoreClickedHandler();
    const nextElement = document.querySelector(".stories__list .story__inner:last-child") as HTMLElement;
    const scrollTopValue = nextElement.offsetTop + nextElement.offsetHeight;

    setStoriesList((prevState) =>
      [...prevState, MAIN_PAGE_STORY_ITEMS[prevState.length]]);
    document.body.scrollTo({
      top: scrollTopValue,
      behavior: "smooth",
    });
  }

  return (
    <div className="main-page__item stories">
      <div className="page-width">
        <h2 className="h1 main-page__title stories__title">Истории наших клиентов</h2>
        <div className="stories__list">
          {storiesList.map((item, index) =>
            <Item data={item} key={index} />)}
        </div>
        <div className="story__buttons">
          {storiesList.length < MAIN_PAGE_STORY_ITEMS.length && (
            <div className="story__btn" onClick={showMore}>
              <Button
                text="Читать ещё одну историю"
                size={ButtonSizeEnum.Large}
                color={ButtonColorEnum.Light}
                iconPosition={ButtonIconPositionEnum.Right}
              />
            </div>
          )}
          <Link
            className="story__btn"
            onClick={marketingMainPage6}
            to={`/${CATALOG_PAGE_NAME}`}
          >
            <Button
              text="Посмотреть экспертов"
              size={ButtonSizeEnum.Large}
              color={ButtonColorEnum.Dark}
              iconPosition={ButtonIconPositionEnum.Right}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Stories;
