export const supportData = {
  title: "Остались вопросы? Напишите\u00a0нам",
  schedule: "Служба поддержки работает с 08:00 до 02:00 мск",
  chips: [
    {
      title: process.env.GATSBY_SUPPORT_EMAIL,
      icon: `Email`,
      link: `mailto:${process.env.GATSBY_SUPPORT_EMAIL}`,
      color: "email-icon",
    },
    {
      title: `WhatsApp`, icon: `WhatsApp`, link: process.env.GATSBY_SUPPORT_WHATSAPP, color: "",
    },
    {
      title: `Telegram`, icon: `Tg`, link: process.env.GATSBY_SUPPORT_TELEGRAM, color: "tg-icon",
    },
  ],
};
