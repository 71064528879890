import * as React from "react";

import { Link } from "gatsby";

import StepBlock from "@/components/common/stepBlock";
import useOnboardingForClient from "@/hooks/useOnboardingForClient";
import { marketingMainPage4 } from "@/marketing/utils";
import {
  Button,
  ButtonColorEnum,
  ButtonIconPositionEnum,
  ButtonSizeEnum,
} from "@components/common/button";
import { CATALOG_PAGE_NAME } from "@components/constants";

import { CONSULTATION_STEP_INFO } from "./constants";

import "./styles.scss";

const ConsultationInstruction = () => {
  const isFFAllowFirstSessionWithoutAnyPaymentOn = process.env.GATSBY_ALLOW_FIRST_VIDEO_SESSION_WITHOUT_PAYMENT === "true";
  const { isActiveOnboarding, linkForOpenOnboarding } = useOnboardingForClient();

  return (
    <div className="main-instruction">
      <div className="page-width main-instruction__inner">
        <StepBlock header={CONSULTATION_STEP_INFO.header} items={CONSULTATION_STEP_INFO.items} />
        <div className="main-instruction__btn">
          <Link onClick={marketingMainPage4} to={isActiveOnboarding ? linkForOpenOnboarding : `/${CATALOG_PAGE_NAME}`}>
            <Button
              text={isFFAllowFirstSessionWithoutAnyPaymentOn
                ? "Получить бесплатную консультацию"
                : "Получить 7 минут"}
              size={ButtonSizeEnum.Large}
              color={ButtonColorEnum.Dark}
              iconPosition={ButtonIconPositionEnum.Right}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ConsultationInstruction;
